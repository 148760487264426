import React from 'react';
import HeroDiagonalAbout from '../components/HeroDiagonalAbout';
import Layout from '../templates/Layout';
import SEO from '../components/SEO';

const SobreNosotrosPage = () => {
  return (
    <Layout>
      <SEO
        pageTitle="Sobre V&A Roofing, Inc."
        pageDescription="Le brindamos a nuestros clientes servicio profesional con productos de alta calidad y al mejor precio del mercado."
      />
      <HeroDiagonalAbout />
      <section className="mx-4 mb-16 md:mb-20 lg:mb-28">
        <article className="container max-w-2xl mx-auto my-8 text-lg md:my-16">
          <h2 className="mb-8 text-2xl font-bold text-center lg:text-3xl">
            Acerca de <strong>V&A Roofing, Inc.</strong>
          </h2>
          <p className="mb-4">
            <strong className="text-red-700">V&A Roofing, Inc.</strong> es una
            empresa localizada en Camuy Puerto Rico en el barrio Yeguada. Inicia
            labores por inspiración del Sr. Ricardo Vargas Rodríguez quién
            atribuye su éxito a las experiencias adquiridas en sus años de
            trabajo en la compañía Danosa Caribbean, Inc. a quiénes agradece las
            oportunidades brindadas en esa época.
          </p>
          <p className="mb-4">
            <strong className="text-red-700">V&A Roofing, Inc.</strong> se
            desarrolla como una empresa familiar. Nuestros trabajos se basan en
            resolver las filtraciones en todas las estructuras construidas en
            Puerto Rico. Actualmente la empresa está registrada en el
            Departamento de Estado y en el Departamento de Asuntos al Consumidor
            de Puerto Rico (DACO). Cuenta también con la capacidad de hacer
            negocios a nivel federal ya que está registrado en SAM’s y posee un
            DUNS Number para su integridad empresarial. Asimismo, está incluido
            en el Registro único de licitadores en el Departamento de la
            Administración de Servicios Generales (ASG). Orgullosamente
            pertenece a la Puerto Rico Roofing Contractors Association
            (Asociación de Contratistas de Techos de Puerto Rico) reconocida
            como representante de los mejores contratistas de sellado de techos
            en el mercado.
          </p>
          <p className="mb-4">
            <strong className="text-red-700">V&A Roofing, Inc.</strong> cuenta
            con el apoyo de la empresa manufacturera más importante del producto
            impermeabilizante en el Caribe, Danosa Caribbean Inc., liderada por
            la Sra. Waleska Rivera. Danosa provee la materia prima con la que
            trabajamos y cuya calidad nos permite satisfacer las necesidades de
            los clientes.
          </p>
          <p className="mb-4">
            Con mucho esmero y confianza en nuestras capacidades, fundamos V&A
            Roofing Inc. para el año de 2013. Desde ese año hasta el presente
            hemos servido a nuestro pueblo puertorriqueño sin perder nuestros
            valores encaminados al servicio y la satisfacción del cliente.
            Nuestra misión se resume en esta frase: “Creemos en el poder del
            reconocimiento y la autonomía en el servicio para que el cliente nos
            elija. Nuestro compromiso es fundamental para lograr la fidelidad de
            los clientes”. Esto refuerza y promueve el compromiso diario de los
            empleados a estar atentos a los momentos en que pueden transformar
            la experiencia del cliente.
          </p>

          <h3 className="mt-2 mb-1 font-bold">Misión</h3>
          <p>
            La misión bajo la cual opera{' '}
            <strong className="text-red-700">V&A Roofing, Inc.</strong> es “Dar
            el mejor servicio a nuestros clientes, con precios accesibles y con
            la mejor calidad en sellado para las filtraciones enfatizando
            siempre la superioridad del producto instalado y más aún el proceso
            realizado para la seguridad de nuestro cliente”.
          </p>

          <h3 className="mt-2 mb-1 font-bold">Visión</h3>
          <p>
            Nuestra visión está centrada en convertirnos en la empresa número
            uno en Puerto Rico, estandarizando nuestros procesos y mejorando
            siempre la calidad del servicio a nuestros clientes.
          </p>

          <h3 className="mt-2 mb-1 font-bold">Valores</h3>
          <p>
            <strong className="text-red-700">V&A Roofing, Inc.</strong> es una
            empresa tradicional que mantiene el respeto tanto para nuestros
            clientes como para los compañeros de trabajo, proveedores y sociedad
            en general. Somos honestos y responsables en todas las labores de la
            empresa. También desarrollamos nuestro potencial dentro de la
            empresa con constancia y disciplina.
          </p>
        </article>
      </section>
    </Layout>
  );
};

export default SobreNosotrosPage;
